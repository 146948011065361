
.tab:hover .sub-menu {
  display: block;
}

/* Footer styles */
footer {
  color: black;
  padding: 20px;
  margin-top: 3em;
}


/* body */ 
.main-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.data-container {
  display: flex;
  border: 2px solid blue;
  width: 74%;
  margin-bottom: 2em;
}

.proposal-bottom {
  /*border: 5px solid blue;*/
  display: flex; /* Add flexbox display */
  align-items: center; /* Align items vertically in the center */
  justify-content: space-evenly;
}



.apply-button {
  border: 2px solid #000000;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #000000;
  color: #ffffff;
}

.proposal-skill button {
  background-color: #f5f5f5;
  padding-left: 0.4em;
  padding-right: 0.4em;
  border-radius: 10%;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
      stroke-dashoffset: 0;
  }
}